import { Col, Modal, notification, Select, Spin } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { apiOms } from '../../../../shared/api';
import { TopFilterComponent } from '../../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { defaultFilter, RETAILER_MAPPING_NOTE, retailerColumn, retailersFilter } from './utils';
import { defaultPageConfig } from '../../../../constants';
import { companyType } from '../D2r';
import { RetailerInfoSlider } from '../../../../shared/components/RetailerInfoSlider/RetailerInfoSlider';
import { getStoreAddress } from 'app/AppSeller/Orders/ManualOrder/utils';
import { AuthContext } from 'shared/contexts/Auth';
import { envs } from 'shared/utils/env';
import { HighlightedText } from 'shared/components/HighlightedText';
import { LoadingOutlined } from '@ant-design/icons';

export const Retailer = ({ company }: { company: companyType }) => {
	const [loading, setLoading] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [count, setCount] = useState(0);
	const [filter, setFilter] = useState(defaultFilter);
	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);
	const [isRetailerModalVisible, setIsRetailerModalVisible] = useState<boolean>(false);
	const [retailerId, setRetailerId] = useState<string | number>('');
	const [reloadRetailerList, setReloadRetailerList] = useState<number>(0);
	const [isMappingModalVisible, setIsMappingModalVisible] = useState<boolean>(false);
	const [selectedRetailerId, setSelectedRetailerId] = useState('');
	const [selectedPrimaryRetailerId, setSelectedPrimaryRetailerId] = useState('');
	const [retailerData, setRetailerData] = useState<any>(null);
	const [primaryRetailerData, setPrimaryRetailerData] = useState<any>(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [retailerList, setRetailerList] = useState([]);
	const [primaryListRetailerList, setPrimaryRetailerList] = useState([]);
	const [primarySearchTerm, setPrimarySearchTerm] = useState('');
	const [orderMappingCount, setOrderMappingCount] = useState(0);
	const [hasMounted, setHasMounted] = useState(false);
	const [orderCountLoading, setOrderCountLoading] = useState(false);
	const [retailerToExclude, setRetailerToExclude] = useState<string[]>([]);

	const {
		authState: { selectedCompanyIds, profile }
	} = useContext(AuthContext);
	const { appType } = envs;
	const isWMSPanel = appType === 'AppWms';

	useEffect(() => {
		const fetchPrimaryRetailerList = async () => {
			try {
				const { data } = await apiOms.get(`/d2r/primary/retailers`);
				const retailerMap = data.primaryRetailers.map((d: any) => d.mappedTo);
				setRetailerToExclude(retailerMap);
			} catch (error) {
				errorHandler(error);
			}
		};
		fetchPrimaryRetailerList();
	}, []);

	useEffect(() => {
		const retailerDetail: any = retailerList.find((r: any) => r.id === selectedRetailerId);
		setRetailerData(retailerDetail);
	}, [selectedRetailerId]);

	useEffect(() => {
		const primaryRetailDetail: any = primaryListRetailerList.find((r: any) => r.id === selectedPrimaryRetailerId);
		setPrimaryRetailerData(primaryRetailDetail);
	}, [selectedPrimaryRetailerId]);

	useEffect(() => {
		if (!hasMounted) {
			setHasMounted(true);
			return;
		}

		const fetchOrderCount = async () => {
			try {
				setOrderCountLoading(true);
				const { data } = await apiOms.get(`/retailer/mapping/count/${selectedRetailerId}`);
				setOrderMappingCount(data.orderCount);
			} catch (error) {
				errorHandler(error);
			} finally {
				setOrderCountLoading(false);
			}
		};
		if (selectedRetailerId) {
			fetchOrderCount();
		}
	}, [selectedRetailerId, hasMounted]);

	const topFilterConfig = useMemo(
		() =>
			retailersFilter({
				profile,
				setPageConfigDefault,
				setFilter,
				filter,
				setIsMappingModalVisible
			}),
		[company, filter, pageConfig]
	);
	const columnsConfig = useMemo(() => retailerColumn(setIsRetailerModalVisible, setRetailerId), []);

	const searchParamConstruct = (): URLSearchParams => {
		const searchParam = new URLSearchParams();
		const { searchOptionType, searchValue } = filter;
		const { current, pageSize } = pageConfig;

		if (searchOptionType === 'isActive' && searchValue) {
			searchParam.append('isActive', searchValue);
		}

		if (searchOptionType !== 'isActive' && searchValue) {
			searchParam.append('searchOptionType', searchOptionType);
			searchParam.append('searchText', searchValue);
		}
		searchParam.append('page', current + '');
		searchParam.append('limit', pageSize + '');
		return searchParam;
	};

	const fetchData = async () => {
		try {
			setLoading(true);

			const searchParamVal: URLSearchParams = searchParamConstruct();

			const { data } = await apiOms.get(`/d2r/store-address/list?${searchParamVal.toString()}`);
			if (data?.status) {
				setRetailers(data.storeAddressData);
				setCount(Number(data.totalRecords));
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const handleMappings = async () => {
		try {
			const { data } = await apiOms.post(`/d2r/retailer/mapping`, {
				mappedTo: selectedPrimaryRetailerId,
				retailerId: selectedRetailerId
			});
			setSelectedPrimaryRetailerId('');
			setSelectedRetailerId('');
			setRetailerToExclude([...retailerToExclude, selectedPrimaryRetailerId]);
			notification.success({
				message: 'Retailer Mapping Created',
				description: data.message,
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleSearchByQuery = async (input: string) => {
		setSearchTerm(input);

		const isNumeric = (input: any) => /^[+-]?\d+(\.\d+)?$/.test(input);
		if (isNumeric(input) && input.length > 3) {
			const {
				data: { storeAddressData }
			} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true, 'phone');
			setRetailerList(storeAddressData);
			return;
		}
		const {
			data: { storeAddressData }
		} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true);
		setRetailerList(storeAddressData);
	};

	useEffect(() => {
		handleSearchByQuery(searchTerm);
	}, [searchTerm]);

	const handlePrimarySearchByQuery = async (input: string) => {
		setPrimarySearchTerm(input);
		const isNumeric = (input: any) => /^[+-]?\d+(\.\d+)?$/.test(input);
		if (isNumeric(input) && input.length > 3) {
			const {
				data: { storeAddressData }
			} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true, 'phone');
			setPrimaryRetailerList(storeAddressData);
			return;
		}
		const {
			data: { storeAddressData }
		} = await getStoreAddress(isWMSPanel ? selectedCompanyIds?.[0] : '', input, true);
		setPrimaryRetailerList(storeAddressData);
	};

	useEffect(() => {
		handlePrimarySearchByQuery(primarySearchTerm);
	}, [primarySearchTerm]);

	useEffect(() => {
		fetchData();
	}, [company, filter, pageConfig, reloadRetailerList]);

	const handleFilter = (input: string, option: any) => {
		return option?.children.props.text?.toLowerCase().includes(input.toLowerCase());
	};

	const handleRetailerSelect = (value: string) => {
		setSearchTerm('');
		setSelectedRetailerId(value);
	};
	const handlePrimaryRetailerSelect = (value: string) => {
		setPrimarySearchTerm('');
		setSelectedPrimaryRetailerId(value);
	};

	const handleCloseMappingModal = () => {
		setRetailerData('');
		setPrimaryRetailerData('');
		setSelectedPrimaryRetailerId('');
		setSelectedRetailerId('');
		setIsMappingModalVisible(false);
	};

	return (
		<Col sm={24} lg={24} span={24} className="main">
			<TopFilterComponent {...topFilterConfig} />
			<div className="innerDiv">
				<div className="div w-100 h-100">
					<CustomTablePagination
						columns={columnsConfig}
						data={retailers}
						total={count}
						loading={loading}
						onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
						{...pageConfig}
						shouldRowSelection={false}
						selectedItem={undefined}
						catalog={undefined}
						selectedRowKeys={undefined}
						setSelectedRowKeys={undefined}
						setSelectedItem={undefined}
					/>
				</div>
				{isRetailerModalVisible && (
					<RetailerInfoSlider
						isRetailerModalVisible={isRetailerModalVisible}
						retailerData={retailers.filter((R: any) => R.id === retailerId)}
						setIsRetailerModalVisible={setIsRetailerModalVisible}
						company={company}
						setReloadRetailerList={setReloadRetailerList}
					/>
				)}
				{isMappingModalVisible && (
					<Modal
						title="Retailer Mapping"
						visible={isMappingModalVisible}
						onOk={handleMappings}
						onCancel={handleCloseMappingModal}
						style={{ top: '40' }}
						width={750}
					>
						<p style={{ marginBottom: '20px' }}>
							<strong>{RETAILER_MAPPING_NOTE}</strong>
						</p>

						<div style={{ display: 'flex', gap: '2rem' }}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Select
									placeholder="Select Retailer"
									style={{ width: '300px' }}
									showSearch
									optionFilterProp="children"
									filterOption={handleFilter}
									onSelect={handleRetailerSelect}
									value={selectedRetailerId || undefined}
									onSearch={setSearchTerm}
								>
									{retailerList
										.filter(
											(retailer: { id: string }) =>
												retailer?.id !== selectedPrimaryRetailerId && !retailerToExclude.includes(retailer.id)
										)
										.map(({ id, firstName, lastName, pincode, addressLine1, phone }) => {
											const fullAddress = `(${phone}) ${firstName} ${lastName}, ${pincode}, ${addressLine1} `;
											return (
												<Select.Option key={id} value={id}>
													<HighlightedText text={fullAddress} highlight={searchTerm} />
												</Select.Option>
											);
										})}
								</Select>
								{retailerData && (
									<div style={{ padding: '1rem' }}>
										<p>
											<strong>Name : </strong>
											{retailerData?.firstName} {retailerData?.lastName}
										</p>
										<p>
											<strong>Address : </strong>
											{retailerData?.addressLine1} {retailerData.addressLine2}
										</p>
										<p>
											<strong>Phone : </strong> {retailerData?.phone}
										</p>
										<p>
											<strong>Pincode : </strong> {retailerData?.pincode}
										</p>
										<p>
											<strong>GSTIN : </strong>
											{retailerData.GSTIN ? retailerData.GSTIN : '--'}
										</p>
									</div>
								)}
							</div>

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Select
									placeholder="Select Primary Retailer"
									style={{ width: '300px' }}
									showSearch
									optionFilterProp="children"
									filterOption={handleFilter}
									onSelect={handlePrimaryRetailerSelect}
									value={selectedPrimaryRetailerId || undefined}
									onSearch={setPrimarySearchTerm}
								>
									{primaryListRetailerList
										.filter((retailer: any) => retailer?.id !== selectedRetailerId)
										.map(({ id, firstName, lastName, pincode, addressLine1, phone }) => {
											const fullAddress = `(${phone}) ${firstName} ${lastName}, ${pincode}, ${addressLine1}`;
											return (
												<Select.Option key={id} value={id}>
													<HighlightedText text={fullAddress} highlight={primarySearchTerm} />
												</Select.Option>
											);
										})}
								</Select>
								{primaryRetailerData && (
									<div style={{ padding: '1rem' }}>
										<p>
											<strong>Name : </strong>
											{primaryRetailerData.firstName} {primaryRetailerData.lastName}
										</p>
										<p>
											<strong>Address : </strong>
											{primaryRetailerData.addressLine1} {primaryRetailerData.addressLine2}
										</p>
										<p>
											<strong>Phone : </strong>
											{primaryRetailerData.phone}
										</p>
										<p>
											<strong>Pincode : </strong>
											{primaryRetailerData.pincode}
										</p>
										<p>
											<strong>GSTIN : </strong>
											{primaryRetailerData.GSTIN ? primaryRetailerData.GSTIN : '--'}
										</p>
									</div>
								)}
							</div>
						</div>
						{orderCountLoading ? (
							<Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
						) : (
							selectedRetailerId !== '' && (
								<div style={{ marginLeft: '10px', color: 'red' }}>
									{orderMappingCount} orders will be affected by this mapping
								</div>
							)
						)}
					</Modal>
				)}
			</div>
		</Col>
	);
};
